import AbstractController from './abstract/AbstractController';
import { create } from '@frontify/frontify-finder';
import { Modal, Toast } from 'bootstrap';
import { checkAssetAccess } from '../helpers/frontify-helper';
import { DateTime } from 'luxon';
import { getNotificationToast } from '../helpers/notification-helper';
import translate from '../helpers/translation-helper';
export default class default_1 extends AbstractController {
    static targets = ['wrapper', 'element', 'asset', 'webp', 'jpg', 'finderButton', 'addButton', 'template'];
    static outlets = ['gallery'];
    static values = { multiple: Boolean, checkAccess: String, fallbackId: String, fallbackPreview: String };
    modal;
    assetIds = [];
    prop = 'data-frontify-element-prop';
    addImage = async () => {
        this.spawnFinder(this.multipleValue, this.addAssets);
    };
    addAssets = async (assets) => {
        for (const asset of assets) {
            if (this.multipleValue) {
                this.extendImageRow();
            }
            const element = this.elementTargets[this.elementTargets.length - 1];
            await this.setAssetId(element, asset);
        }
        if (!this.multipleValue) {
            this.addButtonTarget.classList.add('d-none');
        }
        this.modal?.hide();
    };
    spawnFinder = async (allowMultiSelect, onAssetsChosen) => {
        const modalElement = document.getElementById('frontifyModal');
        this.modal = Modal.getOrCreateInstance('#frontifyModal');
        this.modal.hide();
        if (this.modal === null || modalElement === null) {
            return;
        }
        const iframe = modalElement.querySelector('iframe');
        if (iframe !== null) {
            iframe.remove();
        }
        const finder = await create({
            clientId: 'client-z9mkcbf9tr6c6qff',
            domain: 'brand.sbb.ch',
            options: {
                allowMultiSelect: allowMultiSelect,
            },
        }).catch(() => {
            return null;
        });
        if (!finder) {
            return;
        }
        finder.onAssetsChosen(assets => {
            if (this.checkAccessValue === '' || this.checkAccessValue === undefined) {
                return false;
            }
            if (!checkAssetAccess(assets, this.checkAccessValue)) {
                const warningToast = new Toast('#frontifyModal .toast.-warning');
                warningToast.show();
                return;
            }
            if (assets.length > 1 && !allowMultiSelect) {
                const warningToast = new Toast('#frontifyModal .toast.-warning');
                warningToast.show();
                return;
            }
            onAssetsChosen(assets);
        });
        finder.onCancel(() => {
            this.modal?.hide();
        });
        const finderWrapper = document.querySelector('.frontify-finder-wrapper');
        if (finderWrapper === null) {
            return;
        }
        finder.mount(finderWrapper);
        this.modal.show();
    };
    extendImageRow = () => {
        const nextIndex = this.elementTargets.length;
        const template = this.templateTarget.content.cloneNode(true);
        const attributesToUpdate = [
            'id',
            'name',
            'aria-controls',
            'for',
            'aria-labelledby',
            'data-bs-target',
            'data-model',
        ];
        for (const attribute of attributesToUpdate) {
            const elements = template.querySelectorAll(`[${attribute}]`);
            for (const element of elements) {
                element.setAttribute(attribute, element.getAttribute(attribute).replace(/__name__/g, nextIndex.toString()));
            }
        }
        this.wrapperTarget.append(template);
    };
    setAssetId = async (element, asset) => {
        const editedAssetWebp = element.querySelector(`[${this.prop}="webp"]`);
        const editedAssetJpg = element.querySelector(`[${this.prop}="jpg"]`);
        const editedFileNameWrapper = element.querySelector(`[${this.prop}="filename"]`);
        const noLicenseWarningNotification = getNotificationToast('header-image-no-license-warning', element);
        const internalLicenseWarningNotification = getNotificationToast('header-image-internal-license-warning', element);
        const expirationWarningNotification = getNotificationToast('header-image-expiration-warning', element);
        noLicenseWarningNotification?.hide();
        internalLicenseWarningNotification?.hide();
        expirationWarningNotification?.hide();
        if (!asset || !asset.previewUrl || !editedFileNameWrapper || !editedAssetWebp || !editedAssetJpg) {
            return;
        }
        editedFileNameWrapper.innerText = asset.filename;
        element.dataset.id = asset.id;
        const previewURL = new URL(asset.previewUrl);
        previewURL.searchParams.append('width', '400');
        previewURL.searchParams.append('format', 'webp');
        editedAssetWebp.srcset = previewURL.toString();
        const previewURLFallback = new URL(asset.previewUrl);
        previewURLFallback.searchParams.append('width', '400');
        previewURLFallback.searchParams.append('format', 'jpg');
        editedAssetJpg.src = previewURLFallback.toString();
        const valueElement = element.querySelector(`[${this.prop}="value"]`);
        const licencesElement = element.querySelector(`[${this.prop}="licences"]`);
        const expiresOnElement = element.querySelector(`[${this.prop}="expires"]`);
        const idParamElements = element.querySelectorAll(`[data-frontify-link-id-param]`);
        if (!valueElement || !licencesElement || !expiresOnElement) {
            throw new Error('Element not found');
        }
        if (valueElement) {
            valueElement.value = asset.id;
        }
        idParamElements.forEach(element => {
            element.dataset.frontifyLinkIdParam = asset.id;
        });
        if (licencesElement) {
            licencesElement.innerHTML =
                //@ts-ignore (FrontifyAsset.licenses is incorrectly typed as object rather than array)
                asset.licenses.length > 0
                    ? //@ts-ignore (FrontifyAsset.licenses is incorrectly typed as object rather than array)
                        asset.licenses?.map(l => l.title).join(', ')
                    : await translate('news_article.label_no_licenses', 'workflow');
        }
        if (expiresOnElement) {
            expiresOnElement.innerHTML = asset.expiresAt
                ? DateTime.fromISO(asset.expiresAt).toFormat('dd.LL.yyyy')
                : await translate('news_article.label_no_expiration_date', 'workflow');
        }
        //@ts-ignore (FrontifyAsset.licenses is incorrectly typed as object rather than array)
        if (asset.licenses.length < 1 || asset.licenses.find(l => l.title === 'Keine Lizenz')) {
            noLicenseWarningNotification?.show();
        }
        //@ts-ignore (FrontifyAsset.licenses is incorrectly typed as object rather than array)
        if (asset.licenses.length > 0 && asset.licenses.find(l => l.title === 'Intern')) {
            internalLicenseWarningNotification?.show();
        }
        if (asset.expiresAt && DateTime.fromISO(asset.expiresAt).diffNow().valueOf() < 0) {
            expirationWarningNotification?.show();
        }
        element.classList.remove('d-none');
    };
    change = (event) => {
        const id = event.params.id;
        const element = this.elementTargets.find(element => element.dataset.id === id);
        if (!element)
            return;
        this.spawnFinder(false, async (assets) => {
            await this.setAssetId(element, assets[0]);
            this.galleryOutlet.generateThumbnails();
            this.modal?.hide();
        });
    };
    remove = (event) => {
        const id = event.params.id;
        const element = this.elementTargets.find(element => element.dataset.id === id);
        if (!element)
            return;
        const valueElement = element.querySelector(`[${this.prop}="value"]`);
        if (!valueElement) {
            throw new Error('Element not found');
        }
        if (valueElement) {
            valueElement.value = '';
        }
        if (this.multipleValue) {
            element.remove();
        }
        else {
            element.classList.add('d-none');
        }
        if (!this.multipleValue && this.assetIds.length === 0) {
            this.addButtonTarget.classList.remove('d-none');
        }
    };
}

import AbstractController from './abstract/AbstractController';
export default class default_1 extends AbstractController {
    static targets = ['input', 'tab'];
    static outlets = ['clipboard'];
    connect = () => {
        this.activeInput = this.inputTargets[0];
    };
    clickClipboardButton = () => {
        this.clipboardOutlet.openForTextBlocks('', this.activeInput, false);
    };
    clickVideoClipboardButton = () => {
        this.clipboardOutlet.openForVideo('', this.inputTargets, false);
    };
    changeLanguage = (e) => {
        const index = this.tabTargets.indexOf(e.target);
        this.activeInput = this.inputTargets[index];
    };
}

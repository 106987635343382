import AbstractController from './abstract/AbstractController';
export default class default_1 extends AbstractController {
    static targets = ['link', 'list', 'parent', 'child'];
    observer;
    connect() {
        this.initializeObserver();
        this.observeElements();
    }
    initializeObserver() {
        const targetEntries = [];
        this.linkTargets.forEach((link) => {
            targetEntries.push({
                id: link.getAttribute('href')?.replace('#', '') || '',
                intersectionRatio: 0,
                isIntersecting: false,
            });
        });
        this.observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                const targetElement = entry.target;
                if (targetElement?.id !== null) {
                    targetEntries.forEach((targetEntry, i) => {
                        if (targetEntry.id === targetElement.getAttribute('id')) {
                            targetEntries[i] = {
                                id: targetEntry.id,
                                intersectionRatio: entry.intersectionRatio,
                                isIntersecting: entry.isIntersecting,
                            };
                        }
                    });
                }
            });
            let activeElement = null;
            let currentIntersectionRatio = 0;
            targetEntries.forEach(targetEntry => {
                if (targetEntry.isIntersecting && targetEntry.intersectionRatio > currentIntersectionRatio) {
                    currentIntersectionRatio = targetEntry.intersectionRatio;
                    activeElement = document.getElementById(targetEntry.id);
                }
            });
            if (activeElement !== null) {
                const link = this.linkTargets.find(link => link.getAttribute('href') === `#${activeElement.id}`);
                if (link) {
                    this.setActiveLink(link);
                }
            }
        }, {
            threshold: [0, 0.25, 0.5, 0.75, 1],
        });
    }
    observeElements() {
        this.linkTargets.forEach((link) => {
            const id = link.getAttribute('href');
            if (id) {
                const element = document.querySelector(id);
                if (element) {
                    // @ts-ignore
                    this.observer.observe(element);
                }
            }
        });
    }
    setActiveLink(activeLink) {
        this.linkTargets.forEach(link => link.classList.remove('active'));
        activeLink.classList.add('active');
    }
}

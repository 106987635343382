import { Modal } from 'bootstrap';
import ModalController from './abstract/ModalController';
export default class default_1 extends ModalController {
    static targets = [
        'metaInformationWrapper',
        'metaInformationCheckbox',
        'guidelineInputWrapper',
        'submitModalButton',
    ];
    connect() {
        this.element.addEventListener('submit', this.handleSubmit);
    }
    selectionChanged = (e) => {
        if (e.currentTarget instanceof HTMLElement && e.currentTarget?.id == 'word_export_wordExportType_0') {
            this.metaInformationWrapperTarget.classList.remove('d-none');
            this.guidelineInputWrapperTarget.classList.add('d-none');
        }
        else {
            this.guidelineInputWrapperTarget.classList.remove('d-none');
            this.metaInformationWrapperTarget.classList.add('d-none');
            this.metaInformationCheckboxTarget.checked = false;
        }
    };
    handleSubmit = async () => {
        const modalElement = this.element.closest('.modal');
        const modalInstance = modalElement ? Modal.getInstance(modalElement) : null;
        modalInstance?.hide();
    };
}

import AbstractController from './abstract/AbstractController';
import TomSelect from 'tom-select';
import _ from 'lodash';
import FroalaEditor from 'froala-editor';
export default class default_1 extends AbstractController {
    static targets = ['template', 'component', 'list', 'removeButton', 'moveUpButton', 'moveDownButton'];
    static outlets = ['clipboard', 'add-elements', 'delete-confirm'];
    static values = { minItems: { type: Number, default: 1 } };
    templates = [];
    activeTemplate = null;
    nextIndex = 0;
    initialize() {
        // Templates for all possible components
        for (const [index, template] of this.templateTargets.entries()) {
            this.templates.push({
                type: template.dataset.templateType ?? `default-${index}`,
                template: template.content.firstElementChild,
            });
        }
        if (this.templates.length === 1) {
            this.activeTemplate = this.templates[0];
        }
    }
    connect = () => {
        if (this.componentTargets.length > 0) {
            this.updateComponentButtons();
            this.nextIndex = this.componentTargets.length;
        }
        else {
            this.nextIndex = 0;
        }
    };
    updateComponentButtons = () => {
        for (const button of this.moveUpButtonTargets
            .concat(this.moveDownButtonTargets)
            .concat(this.removeButtonTargets)) {
            button.disabled = false;
        }
        if (!_.isEmpty(this.moveUpButtonTargets) && !_.isEmpty(this.moveDownButtonTargets)) {
            this.moveUpButtonTargets[0].disabled = true;
            this.moveDownButtonTargets[this.moveDownButtonTargets.length - 1].disabled = true;
        }
        if (this.minItemsValue > 0)
            setTimeout(() => {
                this.removeButtonTargets[0].disabled = this.componentTargets.length === 1;
            }, 500);
    };
    updateComponentList = (list) => {
        this.listTarget.innerHTML = '';
        for (const component of list) {
            this.listTarget.append(component);
        }
        this.updateComponentButtons();
        for (const [index, component] of this.componentTargets.entries()) {
            this.updateComponentIndex(component);
            this.updateComponentPosition(index + 1, component);
        }
    };
    selectTemplate = () => {
        this.addElementsOutlet.open();
    };
    selectTemplateHotlinks = () => {
        this.addElementsOutlet.open('hotlink');
    };
    addByTemplate = (type) => {
        this.activeTemplate = this.templates.find(template => template.type === type) ?? null;
        if (this.activeTemplate) {
            this.cloneComponent();
        }
    };
    addComponent = (e) => {
        this.cloneComponent(e.currentTarget.hasAttribute('data-open-clipboard'));
    };
    cloneComponent = (openClipboard = false) => {
        if (!this.activeTemplate) {
            return;
        }
        const clonedComponent = this.activeTemplate.template.cloneNode(true);
        clonedComponent.id = Date.now().toString();
        const clonedElement = this.listTarget.appendChild(clonedComponent);
        this.updateComponentIndex(clonedElement);
        for (const select of clonedComponent.querySelectorAll('select')) {
            this.setupTomselect(select);
        }
        for (const textArea of clonedComponent.querySelectorAll('textarea')) {
            this.initNewFroalaEditor(textArea);
        }
        for (const input of clonedComponent.querySelectorAll('input, textarea')) {
            // Values for radio and checkboxes should not be cleared.
            if (input.getAttribute('type') === 'radio' || input.getAttribute('type') === 'checkbox') {
                continue;
            }
            input.value = '';
        }
        this.updateComponentPosition(this.componentTargets.length + 1, clonedElement);
        this.updateComponentButtons();
        if (openClipboard) {
            this.clipboardOutlet.openForTextBlocks(clonedComponent.id, clonedElement.querySelector('[data-clipboard-field-target=input]'), false);
        }
    };
    removeComponent = (event) => {
        const index = this.removeButtonTargets.indexOf(event.currentTarget);
        this.removeFromConfirmation(index);
    };
    removeFromConfirmation = (index) => {
        this.componentTargets[index].remove();
        this.updateComponentButtons();
    };
    removeComponentByButton = (button) => {
        const index = this.removeButtonTargets.indexOf(button);
        this.componentTargets[index].remove();
        this.updateComponentButtons();
    };
    removeLastComponent = () => {
        const index = this.componentTargets.length - 1;
        this.componentTargets[index].remove();
        this.updateComponentButtons();
    };
    removeComponentById = (componentId) => {
        document.getElementById(componentId)?.remove();
    };
    moveComponentUp = (event) => {
        const index = this.moveUpButtonTargets.indexOf(event.currentTarget);
        this.moveComponent(index, -1);
    };
    moveComponentDown = (event) => {
        const index = this.moveDownButtonTargets.indexOf(event.currentTarget);
        this.moveComponent(index, 1);
    };
    moveComponent = (index, direction) => {
        const list = this.componentTargets;
        list[index] = this.componentTargets[index + direction];
        list[index + direction] = this.componentTargets[index];
        this.updateComponentList(list);
    };
    updateComponentIndex = (component) => {
        const index = this.nextIndex;
        this.nextIndex++;
        this.updateInputs(index, component);
        const nestedTemplates = component.querySelectorAll(`template`);
        for (const element of nestedTemplates) {
            // Here we only update the first id of the template
            // Fist occourence corresponds to the collection item we clone
            this.updateInputs(index, element.content, true);
        }
    };
    updateInputs = (index, component, isNested = false) => {
        const attributesToUpdate = [
            'id',
            'name',
            'aria-controls',
            'for',
            'aria-labelledby',
            'data-bs-target',
            'data-model',
        ];
        for (const attribute of attributesToUpdate) {
            const elements = component.querySelectorAll(`[${attribute}]`);
            const check = !isNested ? /__name__/g : /__name__/;
            for (const element of elements) {
                element.setAttribute(attribute, element.getAttribute(attribute).replace(check, index.toString()));
            }
        }
    };
    updateComponentPosition = (index, component) => {
        const positionField = component.querySelector('[data-position-field]');
        if (positionField) {
            positionField.value = index.toString();
        }
    };
    initNewFroalaEditor(element) {
        element.nextElementSibling?.remove();
        element.value = '';
        new FroalaEditor('#' + element.id, {
            key: 'iTB2xC5C4C3B2C1G3E1pZGCTRSAPJWTLPLZHTQQb1JGZxE2F2G2F1B10B2A1E6C1A1==',
            language: 'de',
            toolbarSticky: true,
            toolbarStickyOffset: 64,
            attribution: false,
            listAdvancedTypes: false,
            inlineClasses: {
                'fr-class-inline': 'inline',
                'fr-class-red-highlighted': 'Rot',
            },
            paragraphStyles: {
                'fr-class-red-highlighted': 'Rot',
            },
            linkList: [
                {
                    text: 'SBB',
                    href: 'https://sbb.ch',
                    target: '_blank',
                },
                {
                    text: 'URL news',
                    href: 'news://',
                    target: '_blank',
                },
                {
                    text: 'URL https',
                    href: 'https://',
                    target: '_blank',
                },
                {
                    text: 'URL http',
                    href: 'http://',
                    target: '_blank',
                },
                {
                    text: 'Mail',
                    href: 'mailto://',
                },
                {
                    text: 'Telefon',
                    href: 'tel://',
                },
            ],
            linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
            events: {
                'popups.show.link.insert': function () {
                    if (!this.popups.get('link.insert')[0].querySelector('[id^="fr-link-target"]')) {
                        return;
                    }
                    this.popups.get('link.insert')[0].querySelector('[id^="fr-link-target"]').checked = true;
                },
            },
            pastePlain: true,
            toolbarButtons: [
                'bold',
                'italic',
                'subscript',
                'superscript',
                'underline',
                'strikeThrough',
                'clearFormatting',
                'inlineClass',
                '|',
                'paragraphFormat',
                'paragraphStyle',
                '|',
                'insertLink',
                'insertTable',
                'formatOL',
                'formatUL',
                '|',
                'insertHR',
                'fullscreen',
                'html',
                '|',
                'undo',
                'redo',
            ],
        });
    }
    setupTomselect = (element) => {
        element.nextElementSibling?.remove();
        element.id = '';
        element.className = '';
        new TomSelect(element, { create: true }).clear();
        const defaultValue = element.querySelector('option[selected]');
        if (defaultValue) {
            element.tomselect?.setValue(defaultValue.value);
        }
        if (element.dataset.tomselectValidOptions && element.tomselect) {
            const validOptions = JSON.parse(element.dataset.tomselectValidOptions);
            for (const option in element.tomselect.options) {
                if (!validOptions.includes(option)) {
                    element.tomselect.removeOption(option);
                }
            }
        }
    };
}

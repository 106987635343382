import AbstractController from './abstract/AbstractController';
import { Carousel } from 'bootstrap';
export default class default_1 extends AbstractController {
    static targets = ['carousel', 'slide', 'controls', 'thumbnails', 'thumbnail', 'next', 'prev'];
    carousel;
    connect() {
        this.carouselTarget.addEventListener('slid.bs.carousel', this.syncThumbnails);
        this.setupCarousel();
    }
    slideTargetConnected() {
        this.setupCarousel(true);
    }
    slideTargetDisconnected() {
        this.setupCarousel(true);
    }
    setupCarousel = (sync = false) => {
        if (this.slideTargets.length === 0) {
            return;
        }
        this.carousel = new Carousel(this.carouselTarget, {
            touch: false,
        });
        if (!sync) {
            return;
        }
        const lastIndex = this.slideTargets.length - 1;
        if (this.slideTargets.length > 1) {
            this.controlsTarget.classList.remove('d-none');
            this.thumbnailsTarget.classList.remove('d-none');
            this.generateThumbnails();
        }
        else {
            this.thumbnailsTarget.innerHTML = '';
            this.controlsTarget.classList.add('d-none');
            this.thumbnailsTarget.classList.add('d-none');
        }
        this.slideTargets.forEach((slide, index) => {
            if (index === lastIndex) {
                return;
            }
            slide.classList.remove('active');
        });
        this.slideTargets[lastIndex].classList.add('active');
        this.syncThumbnails();
    };
    generateThumbnails = () => {
        this.thumbnailsTarget.innerHTML = '';
        this.slideTargets.forEach((slide, index) => {
            const thumbnail = document.createElement('img');
            thumbnail.dataset.galleryTarget = 'thumbnail';
            thumbnail.dataset.action = 'click->gallery#slideTo';
            thumbnail.dataset.galleryIndexParam = index.toString();
            const imgElement = slide.querySelector('img');
            if (!imgElement)
                return;
            thumbnail.src = imgElement.src;
            this.thumbnailsTarget.append(thumbnail);
        });
    };
    syncThumbnails = () => {
        this.thumbnailTargets.forEach((thumbnail, index) => {
            const slide = this.slideTargets[index];
            const classList = ['active', 'error'].filter(cls => slide.classList.contains(cls));
            if (classList.length > 0) {
                thumbnail.setAttribute('class', classList.join(' '));
            }
            else {
                thumbnail.removeAttribute('class');
            }
        });
    };
    slideTo = (event) => {
        const index = parseInt(event.params.index);
        this.carousel?.to(index);
    };
    next = () => {
        this.carousel?.next();
    };
    prev = () => {
        this.carousel?.prev();
    };
}

import AbstractController from './abstract/AbstractController';
export default class default_1 extends AbstractController {
    static targets = ['option', 'previewContent', 'editContent'];
    togglePreview = () => {
        const selectedOption = this.optionTargets.find(option => option.checked);
        if (!selectedOption) {
            return;
        }
        if (selectedOption.value === 'preview') {
            this.previewContentTarget.classList.remove('d-none');
            this.editContentTarget.classList.add('d-none');
        }
        else {
            this.previewContentTarget.classList.add('d-none');
            this.editContentTarget.classList.remove('d-none');
        }
    };
}

import ModalController from './abstract/ModalController';
export default class default_1 extends ModalController {
    static outlets = ['collection'];
    confirmIndex = undefined;
    open = (index) => {
        this.confirmIndex = index;
        this.modal.show();
    };
    confirm = () => {
        if (this.confirmIndex === undefined) {
            return;
        }
        this.collectionOutlet.removeFromConfirmation(this.confirmIndex);
        this.close();
    };
    close = () => {
        this.confirmIndex = undefined;
        this.modal.hide();
    };
}

import ModalController from './abstract/ModalController';
export default class default_1 extends ModalController {
    static outlets = ['collection'];
    connect = () => {
        // this.element.addEventListener('hide.bs.modal', this.hideModalCleanup);
    };
    open = (template) => {
        if (template) {
            this.collectionOutlet.addByTemplate(template);
        }
        else {
            this.modal.show();
        }
    };
    add = (e) => {
        const type = e.params.type;
        this.collectionOutlet.addByTemplate(type);
        this.close();
    };
    close = () => {
        this.modal.hide();
    };
}
